import { Icon, Text } from "components/atoms";
import "./styles.scss";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface IPromocionBannerTira {
  card: {
    tipo_disenio: {
      id: number;
      titulo: string;
    };
    titulo_card: string;
    color: string;
    color_fuente: string;
    color_secundario: string;
    descuento: number;
    descuento_life: number;
    igv: number;
    img: {
      path: string;
      alt: string;
    };
    logo: {
      path: string;
      alt: string;
    };
    precio: number;
    precio_anterior: number;
    subtitulo: string;
    titulo: string;
    valor: number;
  };
  categoria: {
    id: number;
    titulo: string;
  };
  color_promocion: string;
  entidad: null | string;
  hoteles: any[];
  id: number;
  is_formulario: boolean;
  nombre: string;
  redireccion: string;
  mensaje: string;
  logo_banner_tira: {
    path: string;
    alt: string;
  };
  mensaje_mobile: string;
}


interface IBannerTiraPromocion {
  onClose: () => void;
  promoBannerTira: IPromocionBannerTira | null;
}

const BannerTiraPromocion = ({ onClose, promoBannerTira }: IBannerTiraPromocion) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);

  const shouldShowBanner = !(
    location.pathname == promoBannerTira?.redireccion
  );

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    });
  }, []);

  return (
    <>
      {(promoBannerTira !== null && shouldShowBanner) && (
        <div
          className="bannertirapromocion"
          style={{ backgroundColor: promoBannerTira?.color_promocion }}
        >
          <div className="isdesktop-promo bannertirapromocion__container" style={{ display: isScrolled ? "none" : "" }}>
            <div className="bannertirapromocion__container--logopromo">
              {promoBannerTira?.card.logo && (
                <img src={promoBannerTira.logo_banner_tira?.path} alt={promoBannerTira.logo_banner_tira?.alt} />
              )}
            </div>
            <div className="bannertirapromocion__container--text">
              <Text
                type="paragraph"
                text={promoBannerTira?.card.titulo}
                className="texto-descuento"
                style={{ color: promoBannerTira?.card.color_fuente || "#3300E5" }}
              />

              <div className="contentvalor" style={{ color: promoBannerTira?.card.color_fuente || "#3300E5" }}>
                <Text text={`${promoBannerTira?.card.valor}`} className="contentvalor__valor" />
                <div className="contentvalor--right">
                  <Text text={"%"} className="contentvalor--right__percentage" />
                  <Text text={"dscto."} className="contentvalor--right__dscto" />
                </div>
              </div>

              {!!(promoBannerTira?.card.descuento_life && (promoBannerTira?.card.descuento_life > 0)) && (
                <Text
                  type="paragraph"
                  text={t("bannerTiraPromocion.lifeDiscount", { discount: promoBannerTira?.card.descuento_life })}
                  className="texto-descuento-life"
                  style={{ color: promoBannerTira?.card.color_fuente || "#3300E5", backgroundColor: promoBannerTira?.card.color_secundario || promoBannerTira?.color_promocion }}
                />
              )}

              <Text
                type="paragraph"
                text={promoBannerTira?.mensaje}
                className="texto-promocion"
                style={{
                  color: promoBannerTira?.card.color_fuente || "#3300E5",
                  // maxWidth: promoBannerTira?.mensaje.length > 84 ? '300px' : '250px'
                  maxWidth: '300px'
                }}
              />
            </div>

            <div className="button-close">
              <button
                className="redirect gold"
                onClick={() => navigate(promoBannerTira?.redireccion)}
                style={{ backgroundColor: promoBannerTira?.card.color_secundario, color: promoBannerTira?.card.color_fuente }}
              >
                {t("bannerTiraPromocion.buttonText")}
              </button>
            </div>
            <button
              onClick={onClose}
              className="close"
              style={{ color: promoBannerTira?.card.color_fuente || "#3300E5" }}
            >
              <Icon name="close" list="items" width={18} height={18} color={promoBannerTira?.card.color_secundario} />
            </button>
          </div>

          {/* MOBILE DESIGN */}
          <div
            className="bannertirapromocion__container ismobile-promo"
            style={{ display: isScrolled ? "none" : "" }}
            onClick={() => navigate(promoBannerTira?.redireccion)}
          >
            <div className="bannertirapromocion__container--logopromo">
              {promoBannerTira?.card.logo && (
                <img src={promoBannerTira.logo_banner_tira?.path} alt={promoBannerTira.logo_banner_tira?.alt} />
              )}
            </div>
            <div className="bannertirapromocion__container--text">
              <div className="mobile-seccion-descuento">
                <Text
                  type="paragraph"
                  text={t("bannerTiraPromocion.upTo")}
                  className="texto-descuento"
                  style={{ color: promoBannerTira?.card.color_fuente || "#3300E5", fontSize: "18px" }}
                />

                <div className="contentvalor" style={{ color: promoBannerTira?.card.color_fuente || "#3300E5" }}>
                  <Text text={`${promoBannerTira?.card.valor}`} className="contentvalor__valor" />
                  <div className="contentvalor--right">
                    <Text text={"%"} className="contentvalor--right__percentage" />
                    <Text text={"dscto."} className="contentvalor--right__dscto" />
                  </div>
                </div>
              </div>

              <Text
                type="paragraph"
                text={promoBannerTira?.mensaje_mobile}
                className="texto-descuento-life"
                style={{ color: promoBannerTira?.card.color_fuente || "#3300E5", backgroundColor: promoBannerTira?.card.color_secundario || promoBannerTira?.color_promocion }}
              />
            </div>
            <div className="button-close">
              <Icon
                name={"arrow-body-right"}
                list={"arrows"}
                width={30}
                height={22}
                color={promoBannerTira?.card.color_secundario}
                className="arrow-right"
              />

              <button
                onClick={onClose}
                className="close"
              >
                <Icon name="close" list="items" width={30} height={20} color={promoBannerTira?.card.color_secundario} />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BannerTiraPromocion;
