import Axios from "actions/api";
import { endpoints } from "actions/endpoints/services";
import { setDataUtils } from "redux/actions/language";
import * as Sentry from "@sentry/react";
import { NavigateFunction } from "react-router-dom";

interface IGetUtils {
  language?: string;
  navigate: NavigateFunction;
}

export const getUtils = async ({ language, navigate }: IGetUtils) =>
  Axios.request({
    method: "get",
    url: endpoints.utils,
    headers: {
      "Accept-Language": language,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      const data = response.data;

      setDataUtils(data);

      return data;
    })
    .catch((error) => {
      // console.log("Server error: ", error);
      // Sentry.captureException(error);
      // navigate("/es/error");
    });
