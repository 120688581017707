import axios from "axios";
import errorsHttp from "utils/errorsHttp";
import { IAxios } from "./props";
import { REACT_APP_ENDPOINT_CA } from "typings/env";
import * as Sentry from "@sentry/react";
import { updateMetadata } from "utils/function";
import { setErrorAction } from "redux/actions/config";

let cancelToken: () => void;

function getValueLocalStorage(name: string) {
  return window.localStorage.getItem(name);
}

class Axios {
  static cancel = () => cancelToken();

  static async request({
    baseURL = REACT_APP_ENDPOINT_CA,
    method = "get",
    url,
    isAuth,
    payload,
    params,
    headers = {},
  }: IAxios) {
    const methodTemplate: any = {
      get: "GET",
      post: "POST",
      put: "PUT",
      del: "DELETE",
    };
    setErrorAction(null);
    const headersTemplate: any = headers;

    if (isAuth) {
      headersTemplate.Authorization = `Bearer ${getValueLocalStorage("token")}`;
    }

    return axios
      .create({ baseURL })({
        method: methodTemplate[method],
        headers: headersTemplate,
        data: payload,
        url,
        params,
        cancelToken: new axios.CancelToken((c) => (cancelToken = c)),
      })
      .then((response) => response)
      .catch((error) => {
        let errorByType = {
          message: "General error",
          pageRedirect: "500",
        };

        if (axios.isAxiosError(error) && error.response) {
          if (error.response.status === 403) {
            errorByType = {
              message: "Forbidden: No tienes permiso para acceder a este recurso",
              pageRedirect: "403",
            };
          } else if (error.response.status === 404) {
            errorByType = {
              message: "Not Found: El rescurso solicitado no se encontró.",
              pageRedirect: "404",
            };
          } else if (error.response.status === 500) {
            errorByType = {
              message: "Server Error: Hubo un error en el servidor.",
              pageRedirect: "500",
            };
          }
        }

        Sentry.captureException(error);
        console.error(errorByType.message);

        if(method == "get"){
          setErrorAction(errorByType.pageRedirect)
        }

        throw error;
      });
  }
}

export default Axios;
