import { useEffect, useRef } from "react";
import { IBannerVideo } from "./props";
import { Button, Paragraph } from "components/atoms";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const BannerVideo = ({ videoUrl, title, subtitle, hotelesRef, scrollToSection, bannerRef, hoteles}: IBannerVideo) => {
  const { t } = useTranslation();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleRedireccionar = () => {
    scrollToSection(hotelesRef);
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (canvas && video) {
      const ctx = canvas.getContext('2d');

      const draw = () => {
        if (ctx) {
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          requestAnimationFrame(draw);
        }
      };

      const loadedDataListener = () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        draw();
      };

      video.addEventListener('loadeddata', loadedDataListener);

      return () => {
        video.removeEventListener('loadeddata', loadedDataListener);
      };
    }
  }, []);

  return (
    <section className="section__video" ref={bannerRef}>
      <canvas className="banner__video" ref={canvasRef} />
      <video
        autoPlay
        loop
        playsInline
        ref={videoRef}
        muted
        className="banner__video">
        <source src={videoUrl} type="video/mp4" />
      </video>
      <div className='section__banner-content'>
        <div className='d-flex'>
          <div>
            <Paragraph
                family='tpage'
                text={ title }
              />
          </div>
        </div>

        <div className='d-flex'>
          <Paragraph text={subtitle} className='pr-0-2rem' family='sbpage'/>
        </div>

        <div className='pt-2rem isdesktop'>
          <Button type='icongold' onClick={handleRedireccionar}>
            {hoteles?.length === 1 ? t("destinationCity.seeHotel") : t("destinationCity.seeHotels")}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default BannerVideo;
