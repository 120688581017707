import store from 'redux/store';
import * as configSlice from '../slices/config';

export const toggleDarkmode = (value: boolean) => {
  store.dispatch(configSlice.setDarkmode(value))
}

export const setLanguage = (value: string) => {
  document.documentElement.lang = value;
  store.dispatch(configSlice.setLanguage(value))
  localStorage && localStorage.setItem('selectedLanguage', value)
}

export const setCoin = (value: any) => {
  store.dispatch(configSlice.setCoin(value));
};

export const setErrorAction = (value: any) => {
  store.dispatch(configSlice.setError(value));
};