
import Axios from "actions/api";
import { endpoints } from "actions/endpoints/services";
import * as Sentry from '@sentry/react';

export const getMenuOferta = async ({
  language,
  setDataMenu,
  navigate
}: any) => {
    Axios.request({
      method: "get",
      url: endpoints.menuofertas,
      headers: {
        "Accept-Language": language,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    }).then((response) => {
      const data = response.data;
      setDataMenu(data);
      return data;
    }).catch((error : unknown) => {
      // alert('No se pudo cargar menu')
      // console.log("Server error: ", error);
      // Sentry.captureException(error);
      // navigate('/es/error');
    })

}
