import React, {
  useState,
  useEffect,
  useCallback,
} from "react";
import Section from "components/molecules/section";
import { BannerSlider } from "components/organisms";
import { Text } from "components/atoms";
import "./styles.scss";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useAppSelector } from "hooks/useAppSelector";
import { getUtils } from "actions/utils/services";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { REACT_APP_ENDPOINT_CA, REACT_APP_RECAPTCHA_API_KEY } from "typings/env";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { logSentryErrorResponse } from "utils/function";
import * as Sentry from '@sentry/react';

interface FormValues {
  nombres_titular: string;
  apellidos_titular: string;
  tipo_documento_id_titular: string;
  numero_documento_titular: string;
  email_titular: string;
  domicilio_titular: string;
  documento_file_titular: FileList;
  nombres_representante: string;
  apellidos_representante: string;
  tipo_documento_id_representante: string;
  numero_documento_representante: string;
  documento_file_representante: FileList;
  documento_acredite_file_representante: FileList;
  acceso: boolean;
  rectificacion: boolean;
  cancelacion: boolean;
  oposicion: boolean;
  alcance_solicitud: string;
  informacion_adicional: FileList;
}

const Arco = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isValid },
    reset,
  } = useForm<FormValues>({
    mode: "onChange",
  });

  const [token, setToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tokenReceived, setTokenReceived] = useState(false);

  const { language, tipo_documentos } = useAppSelector((state) => ({
    language: state.config.language,
    tipo_documentos: state.language.dataUtils?.tipo_documentos || [],
  }));

  const verifyRecaptchaCallback = useCallback((token: string) => {
    setToken(token);
    setTokenReceived(true);
  }, []);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      setIsLoading(true);
      const formData = new FormData();

      const dataToSend = {
        ...data,
        acceso: data.acceso ? 1 : 0,
        rectificacion: data.rectificacion ? 1 : 0,
        cancelacion: data.cancelacion ? 1 : 0,
        oposicion: data.oposicion ? 1 : 0,
        tipo_documento_id_titular: parseInt(data.tipo_documento_id_titular, 10),
        tipo_documento_id_representante: data.tipo_documento_id_representante ? parseInt(data.tipo_documento_id_representante, 10) : undefined,
      };

      for (const key in dataToSend) {
        if (dataToSend.hasOwnProperty(key)) {
          const value = dataToSend[key as keyof FormValues];
          if (value instanceof FileList) {
            formData.append(key, value[0]);
          } else if (value !== undefined) {
            formData.append(key, value.toString());
          }
        }
      }

      const response = await fetch(`${REACT_APP_ENDPOINT_CA}/form/arco`, {
        method: 'POST',
        headers: {
          "Accept": "application/json",
          "g-recaptcha-response": token,
        },
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        toast.success(data.message, { position: "top-right" });
        reset();
      } else {
        logSentryErrorResponse(response, 'Form - ARCO Form');
        console.error(response);
        toast.error(t("forms.errorSendingData"), { position: "top-right" });
      }

    } catch (error) {
      // Sentry.captureException(error); // Captura el error en Sentry
      // console.error(error);
      toast.error(t("forms.errorSendingData"), { position: "top-right" });
    } finally {
      setIsLoading(false);
    }
  };

  const datas = {
    banners: [
      // Cambiar a "banners" en lugar de "banner"
      {
        id: 1,
        titulo: "ARCO",
        // subtitulo: "CORPORATIVAS",
        color_fondo_logo: "rgba(62, 145, 41, 1)",
        img_desktop: {
          name: "banner-home",
          path: "https://s3.us-east-1.amazonaws.com/multimediaqa.casa-andina.com/images/1440X700_términoscondicionescasaandinahoteles.JPG",
          width: 1440,
          height: 400,
          size: 794666,
          title: "Banner Home",
          alt: "Banner Home",
          description: null,
          caption: "Banner Home",
        },
        img_mobile: [],
        descuento: "25",
        descripcion_boton: "",
        redireccion_boton: "",
        img_logos: [],
        tipo_banner: {
          id: 1,
          titulo: "Título/subtitulo collage logo",
        },
      },
    ],
  };

  useEffect(() => {
    getUtils({ language, navigate });
    window.scrollTo(0, 0);
  }, [language]);

  return (
    <>
      <BannerSlider data={datas.banners} gradiente />
      <Section>
        <div className="arco">
          <div className="arco__container">
            <Text
              type="paragraph"
              text={t("forms.arcoIntro")}
            />

            <br />

            <Text
              type="paragraph"
              text={t("forms.requestArcoInfo")}
            />

            <br />

            <div className="arco__container--form">
            <GoogleReCaptchaProvider
              reCaptchaKey={(REACT_APP_RECAPTCHA_API_KEY as string) || ""}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="columinputs">
                  <h3>{t("forms.personalData")}</h3>
                  <div className="maininputs">
                    <div>
                    <input
                      type="text"
                      id="nombres_titular"
                      placeholder={`${t("forms.firstNames")}*`}
                      {...register("nombres_titular", { required: true })}
                      />
                    {errors.nombres_titular &&
                      <span>{t("forms.requiredField")}</span>
                    }
                    </div>

                    <div>

                    <input
                      type="text"
                      id="apellidos_titular"
                      placeholder={`${t("forms.lastNames")}*`}
                      {...register("apellidos_titular", { required: true })}
                      />
                    {errors.apellidos_titular &&
                      <span>{t("forms.requiredField")}</span>
                    }
                    </div>
                  </div>
                  <div className="columinputs2">
                    <label>{`${t("forms.documentType")}*`}</label>
                    <div className="maininputs2">
                      <div>
                      <select
                        id="tipo_documento_id_titular"
                        {...register("tipo_documento_id_titular", {
                          required: true,
                        })}
                      >
                        <option value="">{t("forms.chooseDocument")}</option>
                        {tipo_documentos.map((tipo_documento: any) => (
                          <option
                            key={tipo_documento.id}
                            value={tipo_documento.id}
                          >
                            {tipo_documento.nombre}
                          </option>
                        ))}
                      </select>
                      {errors.tipo_documento_id_titular &&
                        <span>{t("forms.requiredField")}</span>
                      }
                      </div>

                      <div>
                      <Controller
                        name="numero_documento_titular"
                        control={control}
                        rules={{
                          required: t("forms.dniRequired"),
                          pattern: {
                            value: /^[0-9]+$/,
                            message: t("forms.dniNumbersOnly"),
                          },
                          maxLength: {
                            value: 8,
                            message: t("forms.dni8Digits"),
                          },
                          minLength: {
                            value: 8,
                            message: t("forms.dni8Digits"),
                          },
                        }}
                        render={({ field }) => (
                          <input
                            type="text"
                            id="numero_documento_titular"
                            placeholder={`${t("forms.documentNumber")}*`}
                            {...field}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const value = e.target.value
                                .replace(/\D/g, "")
                                .slice(0, 8);
                              field.onChange(value);
                            }}
                          />
                        )}
                      />
                      {errors.numero_documento_titular && <span>{errors.numero_documento_titular.message}</span>}
                      </div>
                    </div>
                  </div>
                  <div className="columinputs1">
                    <label>{`${t("forms.attachDocument")}*`}</label>
                    <Controller
                      name="documento_file_titular"
                      control={control}
                      rules={{ required: t("forms.documentRequired") }}
                      render={({ field }) => (
                        <input
                          type="file"
                          onChange={(e) => field.onChange(e.target.files)}
                        />
                      )}
                    />
                    {errors.documento_file_titular &&
                      <span>{errors.documento_file_titular.message}</span>
                    }
                    <p>{t("forms.documentMaxSize")}</p>
                  </div>
                  <div className="maininputs">
                    <div>
                      <Controller
                        name="email_titular"
                        control={control}
                        rules={{
                          required: t("forms.emailRequired"),
                          pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: t("forms.validEmail"),
                          },
                        }}
                        render={({ field }) => (
                          <input
                            type="email"
                            id="email_titular"
                            placeholder={`${t("forms.email")}*`}
                            {...field}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              let value = e.target.value;
                              if (value.endsWith('.con')) {
                                value = value.slice(0, -4) + '.com';
                              }
                              field.onChange(value);
                            }}
                          />
                        )}
                      />

                      {errors.email_titular && <span>{errors.email_titular.message}</span>}
                    </div>

                    <div>
                      <input
                        type="text"
                        id="domicilio_titular"
                        placeholder={`${t("forms.address")}*`}
                        {...register("domicilio_titular", { required: true })}
                      />
                      {errors.domicilio_titular &&
                        <span>{t("forms.requiredField")}</span>
                      }
                    </div>
                  </div>
                </div>

                <div className="columinputs">
                  <h3>
                    {t("forms.representativeData")}
                  </h3>
                  <div className="maininputs">
                    <div>
                      <input
                        type="text"
                        placeholder={`${t("forms.firstNames")}*`}
                        {...register("nombres_representante", {
                          required: false
                        })}
                        />
                      {errors.nombres_representante &&
                        <span>{t("forms.requiredField")}</span>
                      }
                    </div>

                    <div>
                      <input
                        type="text"
                        placeholder={`${t("forms.lastNames")}*`}
                        {...register("apellidos_representante", {
                          required: false
                        })}
                        />
                      {errors.apellidos_representante &&
                        <span>{t("forms.requiredField")}</span>
                      }
                    </div>
                  </div>
                  <div className="columinputs2">
                    <label>{`${t("forms.documentType")}*`}</label>
                    <div className="maininputs2">
                      <div>
                        <select
                          id="tipo_documento_id_representante"
                          {...register("tipo_documento_id_representante", {
                            required: false,
                          })}
                        >
                          <option value="">{t("forms.chooseDocument")}</option>
                          {tipo_documentos.map((tipo_documento: any) => (
                            <option
                              key={tipo_documento.id}
                              value={tipo_documento.id}
                            >
                              {tipo_documento.nombre}
                            </option>
                          ))}
                        </select>
                        {errors.tipo_documento_id_representante && (
                          <span>{t("forms.requiredField")}</span>
                        )}
                      </div>

                      <div>
                        <input
                            type="text"
                            placeholder={t("forms.documentNumber")}
                            {...register("numero_documento_representante", {
                              required: false,
                              pattern: {
                                value: /^[0-9]+$/,
                                message: t("forms.dniNumbersOnly"),
                              },
                              maxLength: {
                                value: 8,
                                message: t("forms.dni8Digits"),
                              },
                              minLength: {
                                value: 8,
                                message: t("forms.dni8Digits"),
                              },
                            })}
                          />
                          {errors.numero_documento_representante && (
                            <span>{errors.numero_documento_representante.message}</span>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="columinputs1">
                    <label>{t("forms.attachDocument")}</label>
                    <Controller
                      name="documento_file_representante"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="file"
                          onChange={(e) => field.onChange(e.target.files)}
                        />
                      )}
                    />
                    <p>{t("forms.documentMaxSize")}</p>
                  </div>
                  <div className="columinputs1">
                    <label>{t("forms.representativeDoc")}</label>
                    <Controller
                      name="documento_acredite_file_representante"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="file"
                          onChange={(e) => field.onChange(e.target.files)}
                        />
                      )}
                    />
                    <p>{t("forms.documentMaxSize")}</p>
                  </div>
                </div>

                <div>
                  <h3>{t("forms.requestType")}</h3>
                  <div className="columlabels">
                    <label className="columlabels__input">
                      <input type="checkbox" {...register("acceso")} />
                      <p>
                      {t("forms.access")}
                      </p>
                    </label>
                    <label className="columlabels__input">
                      <input type="checkbox" {...register("rectificacion")} />
                      {t("forms.rectification")}
                    </label>
                    <label className="columlabels__input">
                      <input type="checkbox" {...register("cancelacion")} />
                      {t("forms.cancellation")}
                    </label>
                    <label className="columlabels__input">
                      <input type="checkbox" {...register("oposicion")} />
                      {t("forms.opposition")}
                    </label>
                  </div>
                </div>

                <div className="textareainputs">
                  <label>
                  {t("forms.explainRequest")}
                  </label>
                  <textarea placeholder={`${t("forms.messageRequired")}*`} {...register("alcance_solicitud", {
                    required: true,
                  })} />
                  {errors.alcance_solicitud &&
                    <span>{t("forms.requiredField")}</span>
                  }
                </div>

                <div className="columinputs1">
                  <label>{t("forms.attachDocument")}</label>
                  <Controller
                    name="informacion_adicional"
                    control={control}
                    render={({ field }) => (
                      <input
                        type="file"
                        onChange={(e) => field.onChange(e.target.files)}
                      />
                    )}
                  />
                  <p>{t("forms.documentMaxSize")}</p>
                </div>

                <br />

                <div>
                  <h3>{t("forms.responseNotification")}</h3>

                  <Text
                    family={"txnormal"}
                    className="answer"
                    text={t("forms.responseIntro")}
                  />
                </div>
                <table className="table-arco">
                  <thead>
                    <tr>
                      <th>{t("forms.requestTypes.requestType")}</th>
                      <th>{t("forms.timeframes.timeframe")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t("forms.requestTypes.information")}</td>
                      <td>{t("forms.timeframes.information")}</td>
                    </tr>
                    <tr>
                      <td>{t("forms.requestTypes.revocation")}</td>
                      <td>{t("forms.timeframes.revocation")}</td>
                    </tr>
                    <tr>
                      <td>{t("forms.requestTypes.access")}</td>
                      <td>{t("forms.timeframes.access")}</td>
                    </tr>
                    <tr>
                      <td>{t("forms.requestTypes.rectificationCancellationOpposition")}</td>
                      <td>{t("forms.timeframes.rectificationCancellationOpposition")}</td>
                    </tr>
                  </tbody>
                </table>

                <button type="submit" disabled={!isValid} className="btnsubmit">
                  {isLoading ? t("forms.sending") : t("forms.send")}
                </button>
              </form>
              <GoogleReCaptcha onVerify={verifyRecaptchaCallback} />
              </GoogleReCaptchaProvider>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};

export default Arco;
