import React, { useEffect } from "react";
import { Text } from "components/atoms";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { updateMetadata } from "utils/function";

const ServerError = () => {
  const navigate = useNavigate();

  useEffect(() => {
    updateMetadata({
      title: "500 - Error de servidor",
    });
  }, []);

  return (
    <section className="servererror">
      <div className="servererror__container">
        <div className="servererror__container--text">
          <Text type="title" text="500" />
          <br />
          <Text
            type="subtitle"
            text="Ocurrió un error inesperado en el servidor."
          />
          <Text
            type="subtitle2"
            text="Por favor, intenta nuevamente más tarde."
          />
          <br />
          <Text
            type="paragraph"
            text="Regresa y reservar en nuestros 43 hoteles."
            onClick={() => navigate("/")}
            className="redirect"
          />
        </div>
      </div>
    </section>
  );
};

export default ServerError;
