import { Paragraph, Text, IconText } from "components/atoms";
import { IBannersHeaderB } from "./props";
import "./styles.scss";
import { Banner } from "components/molecules";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const BannersHeaderB = ({
  data,
  type,
  onClick,
  bannerRef,
  hoteles,
}: IBannersHeaderB) => {
  const { t } = useTranslation();

  const bannerDirection = () => {
    if (!data) {
      return (
        <section className="section__banner">
          <motion.div
            initial={{ opacity: 0.5 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.8,
              repeat: Infinity,
              repeatType: "reverse",
            }}
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "#ccc9",
            }}
          >
            <div className={`section__banner-content`} />
          </motion.div>
        </section>
      );
    }

    switch (type) {
      case "center":
        return (
          <Banner
            bgImg={data.img?.path}
            className="bannerdestino"
            bannerRef={bannerRef}
            gradiente
          >
            <div className="bannerdestino__content">
              <Text
                type="title"
                className="bannerdestino__content--title"
                text={
                  data.titulo_banner ? data.titulo_banner :
                  hoteles.length === 1
                    ? t("destinationCity.hotelIn", { city: data.titulo })
                    : t("destinationCity.hotelsIn", { city: data.titulo })
                }
              />
              <Text
                className="bannerdestino__content--subtitle"
                type="subtitle2"
                text={data.subtitulo}
              />
            </div>
            <div className="bannerdestino__button">
              <div className="bannerdestino__button--btn" onClick={onClick}>
                {/* {messages[locale].buttonHotels}
                <Icon name='down' list='arrows' width={24} height={24} color='white' /> */}
                <IconText
                  text={
                    hoteles?.length === 1
                      ? t("destinationCity.seeHotel")
                      : t("destinationCity.seeHotels")
                  }
                  nameIcon="down"
                  listIcon="arrows"
                  w={24}
                  h={24}
                  color="white"
                  type="right"
                />
              </div>
            </div>
          </Banner>
        );
      case "left":
        return (
          <section className="bannerheader" ref={bannerRef}>
            <img
              src={data.img?.path}
              alt={data.img?.alt}
              className="bannerheader__img"
            />
            <div className="bannerheader__container">
              <div className="bannerheader__container--text ta-right">
                <div>
                  <Paragraph text={data.titulo} family="tbanner" />
                </div>
              </div>
            </div>
          </section>
        );
      // case 'right':
      //   return (

      //   )
    }
  };

  return <>{bannerDirection()}</>;
};

BannersHeaderB.defaultProps = {
  default: "center",
};

export default BannersHeaderB;
