import React, { useState } from "react";
import "./styles.scss";
import { Icon, Text } from "components/atoms";
import { Modal } from "components/molecules";
import { useTranslation } from "react-i18next";

const PromotionsYTQP = ({ data, isList }: any) => {
  const [modal, setModal] = useState(false);
  const { t } = useTranslation();

  const toogleModal = () => setModal(!modal);

  return (
    <>
      <div
        className="promotionsyqp"
        // style={{ backgroundColor: data?.color_promocion, color: 'white' }}
      >
        <div className="promotionsyqp__container">
          <div className="promotionsyqp__container--titulo">
            <Text html={{ __html: data.descripcion }} family={"txpromotion"} />

            {data.terminos_condiciones === null ||
            data.terminos_condiciones === "" ||
            !data.terminos_condiciones ? (
              ""
            ) : (
              <div className="terms">
                <p>
                  *{t("promotions.tyc")}
                  <span onClick={toogleModal} className="txtlink">
                    {t("promotions.here")}
                  </span>
                </p>
              </div>
            )}
          </div>
          <div className="promotionsyqp__container--icon">
            <Icon name="rightcopy" list="arrows" width={26} height={45} />
          </div>
          <div className="codigo">
            <Text
              html={{ __html: data.prm_descripcion_2 }}
              family={"txpromotion"}
            />

            {data.servicios &&
              data.servicios.map((item: any, index: number) => (
                <div className="list" key={index}>
                  <img
                    src={item.icono?.path}
                    alt={item.icono?.alt}
                    width={24}
                    height={24}
                  />
                  <Text text={item.titulo} type="paragraph" />
                </div>
              ))}
          </div>
          <Modal
            isTitle={true}
            showModal={modal}
            close={toogleModal}
            title={t("promotions.tycPromotion")}
          >
            <div className="modal__terminos">
              <Text
                html={{ __html: data.terminos_condiciones }}
                type="paragraph"
                className={"paragraph"}
              />
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

PromotionsYTQP.defaultProps = {
  isList: false,
};

export default PromotionsYTQP;
