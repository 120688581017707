import Axios from "actions/api";
import { IGetDestinationsCity, IGetDestinations } from "./props";
import { endpoints } from "actions/endpoints/services";
import {
  setData,
  setDataDestinations,
  setLoading,
} from "redux/actions/language";
import { updateMetadata } from "utils/function";
import * as Sentry from "@sentry/react";

export const getDestinationsCity = async ({
  city,
  coin,
  language,
  navigate,
}: IGetDestinationsCity) => {
  try {
    setLoading(true);
    const destinosciudad = `/destinos/${city}`;

    const response = await Axios.request({
      method: "get",
      url: destinosciudad,
      headers: {
        "Accept-Language": language,
        "Currency-Code": coin,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const data = response.data;

    setData(data);
    updateMetadata({
      title: data?.meta_data?.titulo || "Casa Andina Destinos Ciudad",
      description: data?.meta_data?.descripcion,
      canonicalUrl: data?.meta_data?.[`url_canonica_${language}`],
      hreflang: [
        { hreflang: "es", href: data?.meta_data?.url_canonica_es },
        { hreflang: "en", href: data?.meta_data?.url_canonica_en },
        { hreflang: "pt", href: data?.meta_data?.url_canonica_pt },
      ],
    });

    return data;
  } catch (error) {
    // console.log("Server error: ", error);
    // Sentry.captureException(error);
    // navigate("*", { replace: false });
  } finally {
    setLoading(false);
  }
};

export const getDestinations = async ({
  language,
  navigate,
}: IGetDestinations) => {
  setLoading(true);
  Axios.request({
    method: "get",
    url: endpoints.destinos,
    headers: {
      "Accept-Language": language,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      const data = response.data;

      setDataDestinations(data);
      updateMetadata({
        title: data?.meta_data?.titulo || "Casa Andina Destinos",
        description: data?.meta_data?.descripcion,
        canonicalUrl: data?.meta_data?.[`url_canonica_${language}`],
        hreflang: [
          { hreflang: "es", href: data?.meta_data?.url_canonica_es },
          { hreflang: "en", href: data?.meta_data?.url_canonica_en },
          { hreflang: "pt", href: data?.meta_data?.url_canonica_pt },
        ],
      });

      return data;
    })
    .catch((err) => {
      // alert('No se pudo cargar los destinos')
      // console.log("Server error: ", err);
      // Sentry.captureException(err);
      // navigate("/es/error");
    })
    .finally(() => {
      setLoading(false);
    });
};
