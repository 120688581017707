import Axios from "actions/api";
import { endpoints } from "actions/endpoints/services";
import { setDataHome } from "redux/actions/language";
import { IGetHome } from "./props";
import { updateMetadata } from "utils/function";
import * as Sentry from '@sentry/react';

export const getHome = ({ language, coin, navigate }: IGetHome) => {
  Axios.request({
    method: "get",
    url: endpoints.home,
    headers: {
      "Accept-Language": language,
      "Currency-Code": coin,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      const data = response.data;

      setDataHome(data);
      updateMetadata({
        title: data?.meta_data?.titulo || "Casa Andina Home",
        description: data?.meta_data?.descripcion,
        canonicalUrl: data?.meta_data?.[`url_canonica_${language}`],
        hreflang: [
          { hreflang: "es", href: data?.meta_data?.url_canonica_es },
          { hreflang: "en", href: data?.meta_data?.url_canonica_en },
          { hreflang: "pt", href: data?.meta_data?.url_canonica_pt },
        ],
      });

      return data;
    })
    .catch((err: unknown) => {
      // alert("No se pudo cargar la vista home");
      // console.log("[Actions - getHome] err: ", err);
      // Sentry.captureException(err);
      // navigate('/es/error');
    });
};
