import Config from "redux/types/config"
import { createSlice } from "@reduxjs/toolkit"

const initialState: Config = {
  darkmode: false,
  language: 'es',
  coin: 'USD',
}

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setDarkmode: (state, action) => {
      state.darkmode = action.payload
    },
    setLanguage: (state, action) => {
      state.language = action.payload
    },
    setCoin: (state, action) => {
      state.coin = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
  }
})

export const {
  setDarkmode,
  setLanguage,
  setCoin,
  setError
} = configSlice.actions

export default configSlice.reducer