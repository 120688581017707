import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import i18n from "./utils/lang/config";
import { I18nextProvider } from "react-i18next";
import store from "./redux/store";
import { Provider } from "react-redux";
import "../src/assets/styles/index.scss";
import * as Sentry from "@sentry/react";
import { REACT_APP_SENTRY_DSN, REACT_APP_ENV } from "typings/env";

// Initialize sentry
if (REACT_APP_ENV !== "local") {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    environment: REACT_APP_ENV,
    enabled: true,
    beforeSend(event, hint) {
      const exception = hint.originalException;

      if (exception instanceof Error) {
        console.log("exception.message", exception.message);
        if (
          exception.message.includes("Network Error") ||
          exception.message.includes("Request aborted")
        ) {
          return null;
        }
      }

      if (exception === "Timeout" || exception === "UnhandledRejection") {
        return null;
      }
      return event;
    },
  });
}

// Create root
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
